import React from "react"

const defaultState = {
    dark: false,
    contactFormSent: false,
    quoteFormSent: false,
    projectId: "c6037bbb-d1b3-57e8-8cf4-f375c71c3d19",
}
const ThemeContext = React.createContext(defaultState)
// Getting dark mode information from OS!
const supportsDarkMode = () =>
    window.matchMedia("(prefers-color-scheme: dark)").matches === true

class ThemeProvider extends React.Component {
    state = {
        dark: false,
        contactFormSent: false,
        quoteFormSent: false,
    }

    updateProjectId = projectId => {
        this.setState({ projectId })
    }

    toggleDark = () => {
        let dark = !this.state.dark
        localStorage.setItem("dark", JSON.stringify(dark))
        this.setState({ dark })
        if (dark) {
            this.setTheme("dark")
        } else {
            this.setTheme("light")
        }
    }

    utilizeScroll = elementId => {
        let htmlElRef = document.getElementById(elementId)
        htmlElRef && htmlElRef.scrollIntoView({ behavior: "smooth" })
    }

    handleForm = (event, isValid, formRef) => {
        event.preventDefault()
        event.persist()

        if (!isValid) return
        const form = event.target
        const data = new FormData(form)
        const xhr = new XMLHttpRequest()
        xhr.open(form.method, form.action)
        xhr.setRequestHeader("Accept", "application/json")
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return
            if (xhr.status === 200) {
                if (formRef === "contactForm") {
                    this.setState({ contactFormSent: true })

                    setTimeout(() => {
                        this.setState({ contactFormSent: false })
                    }, 2500)
                }
                if (formRef === "quoteForm") {
                    this.setState({ quoteFormSent: true })

                    setTimeout(() => {
                        this.setState({ quoteFormSent: false })
                    }, 2500)
                }
            } else {
                // TODO: allow toast to handle errors
                return
            }
        }
        xhr.send(data)
    }

    setTheme = themeName => {
        // Get all 'selected' custom CSS properties from the ':root'.
        // These are the variables that are actually used (as oppose to vars to store the alternatives from different themes)
        const selectedCssProps = Array.from(document.styleSheets).reduce(
            (acc, sheet) =>
                (acc = [
                    ...acc,
                    ...Array.from(sheet.cssRules).reduce(
                        (def, rule) =>
                            (def =
                                rule.selectorText === ":root"
                                    ? [
                                          ...def,
                                          ...Array.from(
                                              rule.style
                                          ).filter(name =>
                                              name.startsWith("--selected")
                                          ),
                                      ]
                                    : def),
                        []
                    ),
                ]),
            []
        )

        // Set the selected values to values of a different theme
        selectedCssProps.forEach(prop => {
            // set each selected variable with its analogous variable from the new theme
            document.documentElement.style.setProperty(
                prop,
                `var(--${themeName}${prop.substring(10)})`
            )
        })
    }

    componentDidMount() {
        // Getting dark mode value from localStorage!
        const isDark = JSON.parse(localStorage.getItem("dark"))

        if (isDark) {
            this.setState({ dark: isDark })
        } else if (supportsDarkMode()) {
            this.setState({ dark: true })
        }

        // TODO: enable when dark mode is ready
        // if (!this.state.dark) {
        //     this.setTheme("dark")
        // } else {
        //     this.setTheme("light")
        // }
    }

    render() {
        const { children } = this.props
        const { dark, contactFormSent, quoteFormSent, projectId } = this.state

        return (
            <ThemeContext.Provider
                value={{
                    dark,
                    contactFormSent,
                    quoteFormSent,
                    projectId,
                    toggleDark: this.toggleDark,
                    handleForm: this.handleForm,
                    setTheme: this.setTheme,
                    utilizeScroll: this.utilizeScroll,
                    updateProjectId: this.updateProjectId,
                }}
            >
                {children}
            </ThemeContext.Provider>
        )
    }
}
export default ThemeContext
export { ThemeProvider }
